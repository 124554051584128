@use "../../assets/scss/utils" as *;

.header {
  border-bottom: 1px solid $light_gray_color;
  background-color: $white_color;
  height: $header_height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  .header_left {
    display: flex;
    align-items: center;
    gap: 2rem;

    .iconMenu {
      display: none;
      cursor: pointer;
      margin-left: 2rem;
      width: 3rem;
      height: 3rem;
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      margin-left: 2.4rem;
      color: $black_color;
    }
  }

  .header_right {
    display: flex;
    align-items: center;
    margin-right: 3rem;
    gap: 3rem;

    .notification_icon {
      width: 1.8rem;
      height: auto;
      cursor: pointer;
    }

    .user_blog {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      #user-header {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        object-fit: cover;
      }

      .infos {
        display: flex;
        flex-direction: column;

        p {
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: $very_dark_gray;
        }

        .email {
          font-weight: 400;
        }

        .name {
          font-weight: 500;
        }
      }

      .icon_down {
        cursor: pointer;
      }
    }
  }
}

.ant-dropdown-menu {
  border-radius: 1rem !important;
  padding: 1rem !important;

  .menu-item {
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
      width: 1.7rem;
      height: auto;
      object-fit: contain;

      &:last-child {
        margin-left: -3px;
        margin-right: 4px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .header {
    width: 100%;

    .header_left {
      .iconMenu {
        display: initial;
        width: 3.5rem;
        height: 3.5rem;
      }
    }

    .header_right {
      .user_blog {
        .menu {
          top: 6rem;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .infos {
    display: none !important;
  }
}
