@use "../../assets/scss/utils/" as *;

.main_layout {
  display: flex;

  @media screen and (max-width: 850px) {
    gap: 2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: calc(100vw - $sidebar_width);
    height: 100vh;
    overflow-y: scroll;

    @media screen and (max-width: 1700px) {
      width: calc(100vw - $sidebar_medium_width);
    }

    @media screen and (max-width: 850px) {
      width: 100vw;
    }

    .content-children {
      padding-left: 2rem;

      @media screen and (max-width: 850px) {
        padding-left: 0;
      }
    }
  }

  .small {
    width: calc(100vw - $sidebar_small_width);

    @media screen and (max-width: 850px) {
      width: 100vw;
    }
  }
}
