@use "../../assets/scss/utils" as *;

.small_width {
  width: $sidebar_small_width !important;
}

.sidebar {
  display: flex;
  flex-direction: column;
  position: relative;
  width: $sidebar_width;
  height: 100vh;
  @include gray_fine_border;
  border-radius: 0;
  padding: 2.5rem;
  transition: all 0.2s ease-in;

  .sidebar_top {
    display: flex;
    align-items: center;
    position: relative;
    gap: 2rem;

    .logo2 {
      width: 4.7rem;
      height: 4.8rem;
    }

    .user-sidebar {
      display: flex;
      flex-direction: column;
      transition: all 0.2s ease-in;

      p {
        font-size: 1.6rem;
        font-weight: 500;
        color: $gray_color;
        line-break: anywhere;
      }
    }

    .icon_container {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $light_gray_color;
      background-color: #fff;
      position: absolute;
      cursor: pointer;
      right: -4rem;
      top: -1rem;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;

      img {
        filter: invert(50%) sepia(20%) saturate(451%) hue-rotate(184deg)
          brightness(47%) contrast(81%);
        width: 50%;
        height: 50%;
      }
    }
  }

  .navs {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    gap: 1rem;

    a.active {
      background-color: #f4e8ff;
      border-radius: 1rem;

      img {
        filter: invert(28%) sepia(22%) saturate(2465%) hue-rotate(262deg)
          brightness(57%) contrast(60%);
      }

      p {
        color: #6f27b5;
      }
    }

    a.hover {
      background-color: #f4e8ff;

      img {
        filter: none;
      }

      p {
        color: #6f27b5;
      }
    }
  }

  .sidebar_bottom {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: auto;
    margin-bottom: 2rem;
    align-self: center;
    cursor: pointer;

    p {
      font-size: 1.6rem;
      font-weight: 400;
      color: $very_dark_gray;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 1700px) {
  .sidebar {
    width: $sidebar_medium_width;
  }
}

@media screen and (max-width: 850px) {
  .small_width {
    display: none !important;
  }

  .sideBar_hidden {
    display: none;
  }

  .sidebar {
    position: fixed;
    width: 35rem;
    z-index: 1000;
    background-color: $white_color;
  }
}
@media screen and (max-width: 450px) {
  .sidebar {
    width: 30rem;
  }
}
