@use "../../assets/scss/utils" as *;

.nav_small {
  justify-content: center !important;
}

.nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 4.4rem;
  border-radius: 1rem;
  padding: 1rem;
  gap: 2rem;
  transition: all 0.2s ease-in;

  img {
    filter: invert(48%) sepia(1%) saturate(429%) hue-rotate(314deg)
      brightness(95%) contrast(91%);
    width: 1.8rem;
    height: 1.8rem;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    color: $dark_gray_color;
  }

  &:hover {
    img {
      filter: invert(18%) sepia(92%) saturate(2565%) hue-rotate(262deg)
        brightness(87%) contrast(50%);
    }

    p {
      color: #6f27b5 !important;
    }
  }
}
