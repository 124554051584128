@use "./assets/scss/utils" as *;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif !important;
}

a {
  text-decoration: none;
}

.tostify {
  z-index: 546569566546456;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.2rem;
}

::-webkit-scrollbar-thumb {
  background-color: $purple_color;
  border-radius: 1rem;
}

@media screen and (max-width: 1600px) {
  html {
    font-size: 60%;
  }
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 58%;
  }
}

@media screen and (max-width: 1300px) {
  html {
    font-size: 55%;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }
}

// @media screen and (max-width: 428px) {
//   html {
//     font-size: 42%;
//   }
// }

.react-code-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
