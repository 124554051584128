@use "./_variables" as *;

@mixin gray_fine_border {
  border: 1px solid $light_gray_color;
  border-radius: 0.8rem;
}

@mixin blue_fine_border {
  border: 1px solid $blue-color;
  border-radius: 1.6rem;
}

@mixin purple_fine_border {
  border: 1px solid $purple-color;
  border-radius: 1.6rem;
}

@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin small_section_gray_title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: $very_dark_gray1;
}

@mixin form_blue_title {
  color: $purple-color;
  font-size: 3.2rem;
  font-weight: 500;
}

@mixin border_form-gery {


  align-items: center;
  padding: 10px 10px 10px 12px;
  gap: 18px;

  width: 400px;


  background: #FFFFFF;
  /* Primary Colors/Gray/200 */

  border: 1px solid $purple-color;
  ;
  border-radius: 10px;
}

@mixin gap_form {
  display: flex;
  gap: 7rem;

}