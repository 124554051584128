$white_color: #fff;

$black_color: #000;

$primary_color: #35bbe3;
$blue-color: #2ba7df;

$light_blue_color: #d9f6ff;
$light_blue_color1: #e5f9ff;
$light_blue_color2: #edf9fd;
$light_blue_color3:#f6fcff;
$dark_blue_color: #447f9a;
$dark_blue_color1: #0c76a6;

$light_gray_color: #e4e7ec;
$light_gray_color1: #eff0fb;
$light_gray_color2: #f5f5f5;
$light_gray_color3: #f9f9f9;
$light_gray_color4: #dadada;
$light_gray_color5: #98a2b3;

$gray_color: #98a2b3;

$very_dark_gray: #344054;
$very_dark_gray1: #363636;
$very_dark_gray2: #475467;
$very_dark_gray3: #374957;
$very_dark_gray4: #374957;

$dark_gray_color: #767676;
$dark_gray_color1: #76747c;

$yellow_color: #fcbb45;

$green_color: #28af65;

$red_color: #f75274;

$purple_color:#7225C1;
$purple_color_2:#CBB2E3 ;
$purple-color_3:#806998;
$light_purple_color:#CBB2E3;
$less_purple_color:#e5d3f6;


$header_height: 6.5rem;

$sidebar_width: 30rem;
$sidebar_medium_width: 25rem;
$sidebar_small_width: 10rem;


@media screen and (max-width: 1600) {
  $sidebar_medium_width: 15rem;
}
